import React, { useState } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'

const BAD_SRCS = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

export default function TokenLogo({ address, ...rest }) {
  const base = `https://app.swappi.io/images/1030/${isAddress(address)}`
  const srcs = [`${base}.png`, `${base}.svg`]

  return (
    <Inline>
      <Logo srcs={srcs} {...rest} />
    </Inline>
  )
}

const Logo = ({ srcs, ...rest }) => {
  const [, refresh] = useState(0)

  const src = srcs.find((src) => !BAD_SRCS[src])

  if (src) {
    return (
      <Image
        {...rest}
        alt=""
        src={src}
        onError={() => {
          if (src) BAD_SRCS[src] = true
          refresh((i) => i + 1)
        }}
      />
    )
  }

  return (
    <Inline>
      <span alt={''} style={{ fontSize: 24 }} role="img" aria-label="face">
        🤔
      </span>
    </Inline>
  )
}
