import { networkAddress } from '../constants'

interface BasicData {
  token0?: {
    id: string
    name: string
    symbol: string
  }
  token1?: {
    id: string
    name: string
    symbol: string
  }
}

export const TOKEN_OVERRIDES: { [address: string]: { name: string; symbol: string; decimals: number } } = {
  // [networkAddress.faucetbnb]: {
  //   name: 'BNB',
  //   symbol: 'BNB',
  //   decimals: 18,
  // },
}

export const isNeedOverride = (address: string): boolean => {
  return address && Object.keys(TOKEN_OVERRIDES).includes(address.toLowerCase())
}

// override tokens with incorrect symbol or names
export function updateNameData(data: BasicData): BasicData | undefined {
  if (data?.token0?.id && isNeedOverride(data.token0.id)) {
    data.token0.name = TOKEN_OVERRIDES[data.token0.id].name
    data.token0.symbol = TOKEN_OVERRIDES[data.token0.id].symbol
  }

  if (data?.token1?.id && isNeedOverride(data.token1.id)) {
    data.token1.name = TOKEN_OVERRIDES[data.token1.id].name
    data.token1.symbol = TOKEN_OVERRIDES[data.token1.id].symbol
  }

  return data
}
