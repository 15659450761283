import React from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../Column'
import Title from '../Title'
import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
import { transparentize } from 'polished'
import { TYPE } from '../../Theme'
import { withRouter } from 'react-router-dom'
import { TrendingUp, List, PieChart, Disc } from 'react-feather'
import Link from '../Link'
import { useSessionStart } from '../../contexts/Application'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import Toggle from '../Toggle'
import SwappiLogo from '../../assets/swappi.svg'
import SwappiSingleLogo from '../../assets/swappi-single.png'
import { ReactComponent as GithubIcon } from '../../assets/github.svg'
import { ReactComponent as MediumIcon } from '../../assets/medium.svg'
import { ReactComponent as TelegramIcon } from '../../assets/telegram.svg'
import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg'

const Wrapper = styled.div`
  height: ${({ isMobile }) => (isMobile ? 'initial' : '100vh')};
  /* background-color: ${({ theme }) => transparentize(0.4, theme.bg1)}; */
  color: ${({ theme }) => theme.text1};
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  position: sticky;
  top: 0px;
  z-index: 10000;
  box-sizing: border-box;
  /* background-color: #1b1c22; */
  background: linear-gradient(
    180deg,
    rgba(18, 131, 124, 0.1) 0%,
    rgba(121, 187, 167, 0.066) 57.03%,
    rgba(255, 255, 255, 0) 100%
  );

  backdrop-filter: blur(4px);
  color: ${({ theme }) => theme.bg2};

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
  }

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ activeText }) => (activeText ? '#4DAF9E' : '#2A3D4A')};
  display: flex;
  :hover {
    opacity: 1;
  }
`

const Logo = styled.img`
  margin-left: -6px;
  width: 124px;
  height: 40px;
`

const SmallHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: '20px';
`

const LogoSingle = styled.img`
  margin-left: -2px;
  margin-right: 12px;
  width: 28px;
  height: 28px;
`

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.div`
  margin-right: 0.75rem;
  font-size: 0.825rem;
  font-weight: 500;
  display: inline-box;
  display: -webkit-inline-box;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  a {
    color: ${({ theme }) => theme.white};
  }
`

const OuterLinkWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 2.75rem;
  bottom: 4.5rem;
  > a {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #286590;
    transition: color 0.15s ease;

    &:hover {
      color: #4daf9e;
    }
  }
`

const Polling = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  padding: 1rem;
  color: white;
  opacity: 0.4;
  transition: opacity 0.25s ease;
  :hover {
    opacity: 1;
  }
`
const PollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.green1};
`

const LogoWrapper = styled.div`
  display: flex;
`

const LogoLink = styled.a`
  width: 18px;
  height: 18px;
  margin: 4px 18px 4px 0;
  border-radius: 100%;
`

function SideNav({ history }) {
  const below1080 = useMedia('(max-width: 1080px)')

  const below1180 = useMedia('(max-width: 1180px)')

  const seconds = useSessionStart()

  const [isDark, toggleDarkMode] = useDarkModeManager()

  return (
    <Wrapper isMobile={below1080}>
      {!below1080 ? (
        <DesktopWrapper>
          <AutoColumn style={{ marginLeft: '2.25rem' }}>
            {!below1080 && (
              <AutoColumn gap="1.25rem" style={{ marginTop: '24px' }}>
                <Logo src={SwappiLogo} alt="swappi logo" />
                <BasicLink to="/home">
                  <Option activeText={history.location.pathname === '/home' ?? undefined}>
                    <TrendingUp size={20} style={{ marginRight: '.75rem' }} />
                    Overview
                  </Option>
                </BasicLink>
                <BasicLink to="/tokens">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'tokens' ||
                        history.location.pathname.split('/')[1] === 'token') ??
                      undefined
                    }
                  >
                    <Disc size={20} style={{ marginRight: '.75rem' }} />
                    Tokens
                  </Option>
                </BasicLink>
                <BasicLink to="/pairs">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'pairs' ||
                        history.location.pathname.split('/')[1] === 'pair') ??
                      undefined
                    }
                  >
                    <PieChart size={20} style={{ marginRight: '.75rem' }} />
                    Pairs
                  </Option>
                </BasicLink>

                <BasicLink to="/accounts">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'accounts' ||
                        history.location.pathname.split('/')[1] === 'account') ??
                      undefined
                    }
                  >
                    <List size={20} style={{ marginRight: '.75rem' }} />
                    Accounts
                  </Option>
                </BasicLink>
              </AutoColumn>
            )}
          </AutoColumn>
          {!below1180 && (
            <>
              <OuterLinkWrapper>
                <a href="https://app.swappi.io" target="_blank" rel="noreferrer">
                  Swappi.io
                </a>
                <a href="https://docs.swappi.io/" target="_blank" rel="noreferrer">
                  Documentation
                </a>
                <LogoWrapper>
                  <LogoLink href="https://github.com/swappidex" target="_blank" rel="noreferrer">
                    <GithubIcon style={{ width: '100%', height: '100%' }} />
                  </LogoLink>
                  <LogoLink href="https://t.me/Swappi_DEX" target="_blank" rel="noreferrer">
                    <TelegramIcon style={{ width: '100%', height: '100%' }} />
                  </LogoLink>
                  <LogoLink href="https://twitter.com/SwappiDEX" target="_blank" rel="noreferrer">
                    <TwitterIcon style={{ width: '100%', height: '100%' }} />
                  </LogoLink>
                  <LogoLink href="https://medium.com/@swappidex" target="_blank" rel="noreferrer">
                    <MediumIcon style={{ width: '100%', height: '100%' }} />
                  </LogoLink>
                </LogoWrapper>
              </OuterLinkWrapper>
              <Polling style={{ marginLeft: '1.75rem' }}>
                <PollingDot />
                <a href="/" style={{ color: '#2A3D4A' }}>
                  <TYPE.small color={'#2A3D4A'}>
                    Updated {!!seconds ? seconds + 's' : '-'} ago <br />
                  </TYPE.small>
                </a>
              </Polling>
            </>
          )}
        </DesktopWrapper>
      ) : (
        <MobileWrapper>
          {below1080 && (
            <SmallHeaderWrapper>
              <BasicLink to="/" style={{ display: 'flex', marginRight: '60px' }}>
                <LogoSingle src={SwappiSingleLogo} alt="swappi logo" />
              </BasicLink>
              <AutoColumn gap="1.25rem" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <BasicLink to="/home">
                  <Option activeText={history.location.pathname === '/home' ?? undefined}>Overview</Option>
                </BasicLink>
                <BasicLink to="/tokens">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'tokens' ||
                        history.location.pathname.split('/')[1] === 'token') ??
                      undefined
                    }
                  >
                    Tokens
                  </Option>
                </BasicLink>
                <BasicLink to="/pairs">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'pairs' ||
                        history.location.pathname.split('/')[1] === 'pair') ??
                      undefined
                    }
                  >
                    Pairs
                  </Option>
                </BasicLink>
                <BasicLink to="/accounts">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'accounts' ||
                        history.location.pathname.split('/')[1] === 'account') ??
                      undefined
                    }
                  >
                    Accounts
                  </Option>
                </BasicLink>
              </AutoColumn>
            </SmallHeaderWrapper>
          )}
        </MobileWrapper>
      )}
    </Wrapper>
  )
}

export default withRouter(SideNav)
