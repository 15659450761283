import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

// const url = 'http://localhost:3000/api'
const url = 'https://graphql.swappi.io/subgraphs/name/swappi-dex/swappi-r2'
const blockUrl = 'https://graphql.swappi.io/subgraphs/name/blocklytics/ethereum-blocks'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: url,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: url,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: blockUrl,
  }),
  cache: new InMemoryCache(),
})
