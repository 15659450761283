const eSpaceTestnetAddress = {
  swappifactory: '0x2d57bec97c35e3e6d70cfb02f8a00b4d38bda766',
}

const eSpaceMainnetAddress = {
  usx: '0x422a86f57b6b6F1e557d406331c25EEeD075E7aA',
  wmt: '0xF102A59574d58626C2C4a6c1Dce8831fE408f890',
  neko: '0xbecd75bde87020a4f0d3084bcce9cde794547660',
  wcfx: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
  fauceteth: '0xa47f43de2f9623acb395ca4905746496d2014d57',
  faucetbtc: '0x1f545487c62e5acfea45dcadd9c627361d1616d8',
  faucetusdt: '0xfe97e85d13abd9c1c33384e796f10b73905637ce',
  faucetusdc: '0x6963efed0ab40f6c3d7bda44a05dcf1437c44372',
  faucetbnb: '0x94bd7a37d2ce24cc597e158facaa8d601083ffec',
  faucetmulti: '0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
  faucetzo: '0x8b47a66c0dc369dcb66a206f3c6794df61aaf641',
  faucettad: '0x6138c1073e3cd4a4cf71bcbc8dbf0aaf0cd8e0ca',
  faucetausd: '0xff33b107a0e2c0794ac43c3ffaf637fcea3697cf',
  faucetGol: '0x19aae9e4269ab47ff291125b5c0c2f7296a635ab',
  faucenut: '0xfe197e7968807b311d476915db585831b43a7e3b',
  faucexcfx: '0x889138644274a7dc602f25a7e7d53ff40e6d0091',
  multicall: '0x9f208d7226f05b4f43d0d36eb21d8545c3143685',
  timelock: '0x114e1766968fbc8d68da268e93951524bcdf0e32',
  abc: '0x905f2202003453006eaf975699545f2e909079b8',
  swappifactory: '0xe2a6f7c0ce4d5d300f97aa7e125455f5cd3342f5',
  swappirouter: '0x62b0873055bf896dd869e172119871ac24aea305',
  'ppi-lp btc-eth': '0x5767d71b462464ff77f6fbc81b8377ad49983511',
  'ppi-lp wcfx-eth': '0x8ea70966e8f14337657bff7f40cfb9648f79530b',
  'ppi-lp wcfx-btc': '0x8bbbd6150c933fcd790b4a00bab23826912c192c',
  'ppi-lp eth-usdt': '0xa6943647f22cb9de7a80d1f447db48b0209a812a',
  'ppi-lp btc-usdt': '0x9b2e43277238d4c6a9534caa84cf80cb076810ea',
  'ppi-lp wcfx-usdt': '0x8fcf9c586d45ce7fcf6d714cb8b6b21a13111e0b',
  'ppi-lp wcfx-ppi': '0x1112a6c61a2eec4bd3aec78bd5bf3396bdd37d57',
  'ppi-lp wbtc-ppi': '0xfd683e23e0a70cbe2ca6ef76ea3ef31f70f74f2e',
  'ppi-lp eth-ppi': '0x4812be910bd44d0320f5966ba0e6941a7aaeccc8',
  'ppi-lp usdt-ppi': '0x2ddf0a20b99ad70aee0760f476c24a6568216ed4',
  'ppi-lp wcfx-usdc': '0x0736b3384531cda2f545f5449e84c6c6bcd6f01b',
  'ppi-lp wcfx-bnb': '0x8a61e6cd8eeb564ff66459a2614ce98177f48ca9',
  'ppi-lp usdc-usdt': '0xa5dcd75c853dad730bef4ece3d20f0be7e297a6a',
  'ppi-lp ppi-usdc': '0x31e6ef78c73db56aab43109d50249cab1b0635ef',
  'ppi-lp ppi-eth': '0x4812be910bd44d0320f5966ba0e6941a7aaeccc8',
  'ppi-lp cfx-multi': '0xc563a5d73091afa6e9afebc03002ed5feb2a5c37',
  'ppi-lp cfx-zo': '0x52f0b6ffad6dc033118ee36ac1cc745b9538c9d2',
  'ppi-lp tad-ausd': '0x035c455981a9de6922591d5efe9cb884272836c9',
  'ppi-lp cfx-ausd': '0x0c98f6bab5afec1b149d7219c6cfb5597cdccf97',
  'ppi-lp cfx-gol': '0x93d4be3c0b11fe52818cd96a5686db1e21d749ce',
  'ppi-lp cfx-nut': '0xd9d5748cb36a81fe58f91844f4a0412502fd3105',
  ppi: '0x22f41abf77905f50df398f21213290597e7414dd',
  votingescrowimpl: '0x5df1673fccdbfe9754469d2df890f6363a80118b',
  votingescrowbeacon: '0x6f52bd24a0fa500cc34fb05ad86c9ca403e851b3',
  votingescrow: '0xf270e44105c1270bc7a4ffedbcb699486ada7a6a',
  ppirateimpl: '0xcba8ada2d98254440a0adfafe2481db8469fa1cc',
  ppiratebeacon: '0xa30db713f7d09f8ce9c54c84a8ed8401b4f49547',
  ppirate: '0x71f3136cf723825913bb2cf42b0f87f75223dfad',
  farmcontrollerimpl: '0x8f706f265fa9fcdce66f106792c6a10fcb77ef98',
  farmcontrollerbeacon: '0x7c9a545d4e950697954d67821beab9b9d72044fa',
  farmcontroller: '0xca49dbc049fca1916a1e51315b992a0d1eb308e7',
}

export const networkAddress = eSpaceMainnetAddress

export const FACTORY_ADDRESS = networkAddress.swappifactory

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  'https://gateway.ipfs.io/ipns/tokens.uniswap.org',
  'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
]

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0x495c7f3a713870f68f8b418b355c085dfdc412c3',
  '0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
  '0xe31debd7abff90b06bca21010dd860d8701fd901',
  '0xfc989fbb6b3024de5ca0144dc23c18a063942ac1',
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76',
  '0x93b2fff814fcaeffb01406e80b4ecd89ca6a021b',

  // rebass tokens
  '0x9ea3b5b4ec044b70375236a281986106457b20ef',
  '0x05934eba98486693aaec2d00b0e9ce918e37dc3f',
  '0x3d7e683fc9c86b4d653c9e47ca12517440fad14e',
  '0xfae9c647ad7d89e738aba720acf09af93dc535f7',
  '0x7296368fe9bcb25d3ecc19af13655b907818cc09',
]

export const TOKEN_WHITELIST = [
  networkAddress.wcfx,
  networkAddress.faucetbtc,
  networkAddress.fauceteth,
  networkAddress.faucetusdt,
  networkAddress.ppi,
  networkAddress.faucetusdc,
  networkAddress.faucetbnb,
  networkAddress.faucetmulti,
  networkAddress.faucetzo,
  networkAddress.faucettad,
  networkAddress.faucetausd,
  networkAddress.faucetGol,
  networkAddress.faucenut,
  networkAddress.faucexcfx,
  networkAddress.abc,
  networkAddress.neko,
  networkAddress.wmt,
  networkAddress.usx,
]

// pair blacklist
export const PAIR_BLACKLIST = [
  '0xb6a741f37d6e455ebcc9f17e2c16d0586c3f57a5',
  '0x97cb8cbe91227ba87fc21aaf52c4212d245da3f8',
  '0x1acba73121d5f63d8ea40bdc64edb594bd88ed09',
  '0x7d7e813082ef6c143277c71786e5be626ec77b20',
]

export const PAIR_WHITELIST = [
  networkAddress['ppi-lp wcfx-btc'],
  networkAddress['ppi-lp wcfx-eth'],
  networkAddress['ppi-lp wcfx-usdt'],
  networkAddress['ppi-lp wcfx-ppi'],
  networkAddress['ppi-lp btc-eth'],
  networkAddress['ppi-lp btc-usdt'],
  networkAddress['ppi-lp btc-ppi'],
  networkAddress['ppi-lp eth-usdt'],
  networkAddress['ppi-lp eth-ppi'],
  networkAddress['ppi-lp usdt-ppi'],
  networkAddress['ppi-lp wcfx-usdc'],
  networkAddress['ppi-lp wcfx-bnb'],
  networkAddress['ppi-lp usdc-usdt'],
  networkAddress['ppi-lp ppi-usdc'],
  networkAddress['ppi-lp ppi-eth'],
  networkAddress['ppi-lp cfx-multi'],
  networkAddress['ppi-lp cfx-zo'],
  networkAddress['ppi-lp tad-ausd'],
  networkAddress['ppi-lp cfx-ausd'],
  networkAddress['ppi-lp cfx-gol'],
  networkAddress['ppi-lp cfx-nut'],
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76':
    'TikTok Inc. has asserted this token is violating its trademarks and therefore is not available.',
}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = ['0xd46ba6d942050d489dbd938a2c909a5d5039a161']

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or CFX pairings.'

// pairs that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES_PAIRS = PAIR_WHITELIST

// tokens that should be tracked but arent due to lag in subgraph
// all pairs that include token will be tracked
export const TRACKED_OVERRIDES_TOKENS = TOKEN_WHITELIST

export const FEES_RATE = 0.0017
